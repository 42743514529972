<template>
  <div class="login">
    <div class="container login-wrapper">
      <div class="row align-items-center">
        <div class="col-md-5">
          <img src="../assets/login-img.svg" alt="" />
        </div>
        <div class="col-md-7">
          <h3>Forgot Password</h3>
          <form @submit.prevent="forgotPassword">
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-lab el">Email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                v-model="userName"
                aria-describedby="emailHelp"
              />
              <div
                class="input-errors"
                v-for="(error, errorIndex) of v$.userName.$errors"
                :key="errorIndex"
              >
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <div class="mb-2 action-div">
              <span v-if="this.loginType == 'Nest'"
                >or try with
                <router-link
                  :to="{ name: 'otpEmailLogin', query: { name: 'Nest' } }"
                  >Email</router-link
                ></span
              >
              <span v-else
                >or try with
                <router-link
                  :to="{ name: 'otpEmailLogin', query: { name: 'Pupa' } }"
                  >Email</router-link
                ></span
              >
              <span v-if="this.loginType == 'Nest'"
                >Already have an account
                <router-link :to="{ name: 'login', query: { name: 'Nest' } }"
                  >Login</router-link
                ></span
              >
              <span v-else
                >Already have an account
                <router-link :to="{ name: 'login', query: { name: 'Pupa' } }"
                  >Login</router-link
                ></span
              >
            </div>
            <div class="text-right">
              <button
                type="submit"
                :disabled="isAuthenticating == true"
                class="btn cta-primary"
              >
                <span v-if="isAuthenticating == false">
                  Send
                </span>
                <span v-else>
                  <circular-loader></circular-loader>
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import AuthService from "@/services/AuthService";
import CircularLoader from "@/components/loaders/CircularLoader";
import "bootstrap";
import { required, helpers } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  name: "Login",
  components: {
    CircularLoader
  },
  data() {
    return {
      userName: "",
      loginType: this.$route.query.name,
      isAuthenticating: false
    };
  },
  validations() {
    return {
      userName: {
        required: helpers.withMessage("Please enter user name", required)
      }
    };
  },
  methods: {
    forgotPassword: function() {
      this.v$.userName.$touch();
      if (this.v$.userName.$invalid) {
        return;
      }
      this.isAuthenticating = true;
      AuthService.forgotPassword({
        email: this.userName
      })
        .then(result => {
          this.isAuthenticating = false;
          if (result.data.status == "SUCCESS") {
            this.$toast.success(result.data.message);
          } else {
            this.$toast.error(result.data.message);
          }
        })
        .catch(error => {
          this.error = error;

          this.isAuthenticating = true;
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/login.scss";
</style>
